@use '../../variables' as *;

@mixin container {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.guestbookPageContainer {
  @include display-flex(row, flex-start, flex-start);

  width: 100%;
  height: 100%;
  color: var(--text-01);

  h1 {
    color: var(--text-01);
    margin-top: 0;
    margin-bottom: 2rem;
    scroll-margin-top: 4rem;
  }

  $space-between: 6%;
  $padding-top: 2rem;

  .formContainer {
    @include container;

    position: sticky;
    top: $nav-height;
    height: calc(100vh - $nav-height);
    width: 35%;
    min-width: 23em;
    padding: $padding-top 5vw;
    border-right: var(--content-border);
    background-color: var(--background-02);
  }

  .listContainer {
    @include container;

    width: 65%;
    padding: $padding-top 7% 5rem;

    h1 {
      color: var(--text-01);
      font-size: 2.5em;
    }
  }
}

@media (max-width: 900px) {
  .guestbookPageContainer {
    flex-direction: column;

    .formContainer {
      position: relative;
      top: 0;
      width: 100%;
      min-width: 100%;
      height: fit-content;
      border-right: 0;
      border-bottom: var(--content-border);
      padding: 2em 7vw 3em;
      margin-bottom: 2rem;
      overflow-x: hidden;
    }

    .listContainer {
      width: 100%;
      min-height: 16rem;
    }
  }
}
