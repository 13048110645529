@use '../../variables' as *;
@use 'sass:color';

.adjustWidth {
  width: 100%;
  text-align: center;

  img {
    width: 70%;
  }
}

figure {
  margin: 0 auto;
}

figcaption {
  font-size: 1rem;
  margin: 0.5rem 0 0 0.1rem;
  font-style: italic;
}

.mediaContainer {
  margin-top: 3rem;

  img,
  video {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  img {
    cursor: zoom-in;
  }
}

@mixin align($alignment) {
  width: 34%;
  max-width: 20rem;
  float: $alignment;
  margin: 0;
  margin-bottom: 0.5rem;
}

$float-spacing: 1.75rem;

.floatLeft {
  @include align(left);

  margin-right: $float-spacing;
}

.floatRight {
  @include align(right);

  margin-left: $float-spacing;
}

.mediaGridContainer {
  margin-top: 1.5rem;

  .gridCaption {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-style: normal;
    text-align: center;
    font-weight: 300;
  }
}

.mediaGridComponents {
  @include display-flex(column);

  width: 100%;

  .mediaGridRow {
    @include display-flex(row, flex-start, flex-start);

    width: 100%;
    height: min-content;
  }

  .mediaContainer {
    margin: 0;
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

.statGridContainer {
  @include display-flex(column, flex-start, flex-start);

  flex-wrap: wrap;
  padding: 1.5rem 1.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--layer-025);

  .statContainer {
    @include display-flex(row, flex-start, flex-start);

    width: 100%;
    height: max-content;
    margin-bottom: 0.5rem;
    padding-bottom: 0.4rem;

    .label {
      width: 9rem;
      font-size: 0.9rem;
      color: var(--accent-01);
      line-height: 1.4rem;
      font-weight: 600;
    }

    .stat {
      width: 100%;
      color: var(--text-02);
    }
  }
}

.bulletedListContainer ul {
  padding-left: 3.5rem;
  margin-bottom: 2rem;

  li {
    margin-bottom: 0.15em;
    padding-left: 0.6em;
    vertical-align: middle;

    &::marker {
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 550px) {
  .floatLeft,
  .floatRight {
    @include display-flex(row, center);

    width: 100% !important;
    margin: 0;
    margin-bottom: 1rem;
    float: none;

    img {
      width: 70vw;
      margin: 0 auto;
    }
  }
}

@media (max-width: 900px) {
  figcaption {
    font-size: 1.2rem;
  }

  .floatLeft,
  .floatRight {
    width: 34%;
  }

  .mediaGridContainer {
    figcaption {
      margin-bottom: 0.5em;
    }

    .gridCaption {
      font-size: 1.4rem;
    }
  }
}

.updatedOn {
  width: 100%;
  font-style: italic;
  text-align: center;
  color: var(--text-02);
  margin-top: 0.5em;
  margin-bottom: 3em;
}
