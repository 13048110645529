@use 'variables' as *;
@use 'sass:color';
@import 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap';

$dark-switch-transition: 0.1s ease-in-out;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  transition: background-color $dark-switch-transition,
    border-color $dark-switch-transition;

  ::selection {
    background: var(--blue-10);
  }
}

html {
  background-color: var(--background-01);
}

p,
a,
input,
textarea,
figcaption,
h1,
h2,
h3 {
  transition: background-color $dark-switch-transition,
    border-color $dark-switch-transition;
}

main {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - $nav-height);
  background-color: var(--background-01);
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.05em;
  word-spacing: 0.1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='text'],
textarea {
  border: 0;
  border-bottom: 3px solid var(--content-border-color);
  border-radius: 0.25em;
  padding: 0.4em 0.6em;
  font-size: 1em;
  resize: vertical;
  width: 100%;
  box-sizing: border-box;
  transition: border-color $dark-switch-transition,
    background-color $dark-switch-transition, box-shadow $dark-switch-transition;
  color: var(--text-01);
  box-shadow: 0 0 0 1px var(--content-border-color);

  &:focus {
    border-bottom: 3px solid var(--accent-01-85);
    outline: 0;
  }

  &::placeholder {
    opacity: 0.7;
  }
}

textarea {
  height: 6em;
}

button {
  padding: 0.75em 1.5em;
  border: 0;
  border-radius: 0.3em;
  cursor: pointer;
  background-color: var(--button-primary-01);
  opacity: 1;
  transition: all 0.15s ease-in-out;
  font-size: 1em;
  font-weight: 600;
  color: white;

  &:hover {
    background-color: var(--button-primary-02);
  }

  &:active {
    background-color: var(--button-primary-03);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:active {
      background-color: var(--button-primary-01);
    }
  }
}

@media (max-width: 900px) {
  button {
    width: 100%;
    border-radius: 10em;
    font-size: 1.2rem;
  }
}
