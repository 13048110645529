@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadContainer {
  width: max-content;
  margin: 20% auto 0;

  span {
    $spinner-size: 3rem;

    width: $spinner-size;
    height: $spinner-size;
    background: transparent !important;
    border-radius: 100%;
    border: 6px solid rgba(156, 156, 156, 61.6%);
    border-bottom-color: transparent !important;
    display: inline-block;
    animation: loading 0.6s 0s infinite linear;
  }
}
