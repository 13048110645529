@use '../../variables' as *;

.footerContainer {
  @include display-flex(row, flex-start, flex-start);

  width: 100%;
  flex-wrap: wrap;
  padding: 2rem 5vw 6rem;
  background-color: var(--nav-background-color);
  color: white;
  row-gap: 1.5rem;
  column-gap: 10%;

  h1 {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 0.75em;
  }

  .footerLink {
    width: 100%;
    color: rgba(255, 255, 255, 90%);
    text-decoration: none;

    &:hover {
      color: var(--accent-01);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .horizontal {
      float: left;
    }
  }

  .contentContainer {
    flex-shrink: 0;
    max-width: 20rem;

    &:last-of-type {
      margin-right: 0;
    }

    h3,
    p,
    a {
      width: 100%;
    }

    ul li:last-of-type p {
      margin-bottom: 0.25em;
    }
  }

  .message {
    color: rgba(255, 255, 255, 65%);
    font-weight: 300;
  }

  .socialLinks {
    @include display-flex(row, space-between);

    width: 11em;

    .socialIconLink {
      height: 100%;
      -webkit-tap-highlight-color: initial;
      color: white;
    }
  }

  .otherLinks {
    margin-top: 2.5em;
  }
}

@media (max-width: 900px) {
  .footerContainer {
    padding: 0.5rem 5vw 5rem;
    font-size: 1.1rem;

    .footerLink:hover {
      text-decoration: underline;
    }

    .contentContainer {
      width: 100%;
      max-width: 30rem;
    }
  }
}
