@use '../../variables' as *;

.contentPageWrapper {
  @include display-flex(row, flex-start, flex-start);

  width: 100%;
  height: 100%;
  min-height: calc(100vh - $nav-height);

  $side-menu-width: 15%;

  @mixin side-menu {
    position: sticky;
    top: $nav-height;
    height: calc(100vh - $nav-height);
    overflow-x: visible;
  }

  .sideNavWrapper {
    @include side-menu;

    width: calc($side-menu-width + 0%);
    margin-right: 1%;
    left: 0;
  }

  .contentGeneratorWrapper {
    width: calc(100% - $side-menu-width * 2);
    padding: 1vh 0 7rem;
  }

  .tocWrapper {
    @include side-menu;

    width: calc($side-menu-width - 1%);
  }
}

@media (max-width: 900px) {
  .contentPageWrapper {
    .sideNavWrapper,
    .tocWrapper {
      display: none;
    }

    .contentGeneratorWrapper {
      width: 100%;
      padding: 0 5vw 15vw;
    }
  }
}
