@use '../../variables' as *;
@use 'sass:color';

.tableWrapper {
  width: calc(100% + 3vw);
  height: 100%;
  position: relative;
  right: 3vw;
  overflow-y: auto;
  padding-top: 3rem;

  .tableContainer {
    @include display-flex(column, flex-start, flex-start);

    border-left: var(--content-border);
    padding: 0.5em 1.25em;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 0.75em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .tocLink {
      color: var(--text-03);
      text-decoration: none;
      line-height: 1.5em;

      &:hover {
        color: var(--accent-01);
      }
    }
  }
}

.hideTOC {
  visibility: hidden;
}

@media (max-width: 900px) {
  .tableWrapper {
    display: none;
  }
}
