@use '../../variables' as *;
@use 'sass:color';

nav {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;

  .navLink {
    color: var(--nav-link-color);
    text-decoration: none;
    width: 100%;
    padding: 0.9em 6vw;
  }
}

.desktopNavContainer {
  @include display-flex(row);

  position: relative;
  background-color: var(--nav-background-color);
  margin: 0;
  width: 100%;
  height: $nav-height;
  box-sizing: border-box;
  z-index: 99;

  .logoContainer {
    margin-left: 2.75vw;
    margin-right: 3vw;
  }

  .desktopNav {
    @include display-flex(row);

    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
    font-size: 1.1rem;

    .navLink {
      font-weight: 600;
    }

    .navLink:hover {
      color: var(--accent-01);
    }

    .activeNavItem {
      text-decoration: underline;
      text-underline-offset: 0.4rem;
      color: var(--accent-01);
    }
  }

  .desktopNavItem {
    padding: 0.5em;
    margin-right: 0.75vw;
  }

  .mobileNavButtonContainer {
    display: none;
  }

  .menuIcon {
    width: 2rem;
    filter: invert(0.9);
    cursor: pointer;
  }

  .nightSwitchWrapper {
    margin-left: auto;
    margin-right: 2.75vw;
  }
}

.mobileNavBackdrop {
  position: fixed;
  top: $nav-height;
  right: 0;
  width: 100%;
  height: calc(100vh - $nav-height);
  background-color: rgba(0, 0, 0, 80%);
  opacity: 0;
  transition: opacity 0.125s ease-in-out;
  z-index: 2;
  pointer-events: none;
}

.showBackdrop {
  opacity: 1;
  pointer-events: all;
}

.mobileNavContainer {
  @include display-flex(row, flex-end, flex-start);

  position: fixed;
  top: $nav-height;
  right: 0;
  width: 60%;
  max-width: 100vw;
  min-width: 15em;
  height: calc(100vh - $nav-height);
  background-color: var(--nav-background-color);
  padding: 0;
  transform: translateX(101%);
  transition: transform 0.15s ease-in-out;
  z-index: 3;
  overflow-y: auto;
  border-top: 1px solid var(--gray-70);

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      font-size: 1.4rem;
    }
  }

  .mobileNav {
    overflow: hidden;

    .mobileNavItem {
      @include display-flex(column, flex-start, flex-start);

      transition: all 0.2s ease-in-out;
      overflow: hidden;
      word-wrap: break-word;
      cursor: pointer;

      > .navLink {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }

    .activeNavItem {
      font-weight: 600;
      color: var(--accent-01);
      background-color: var(--blue-gray-65);
      border-bottom: 1px solid var(--blue-gray-60);
      border-top: 1px solid var(--blue-gray-60);
    }

    .subMenuOpen {
      background-color: var(--blue-gray-65);
      border-top: 1px solid var(--blue-gray-60);
      border-bottom: 1px solid var(--blue-gray-60);

      > .navLink {
        border-bottom: 1px solid var(--blue-gray-50);
      }
    }

    .navLinkTextContainer {
      @include display-flex(row, space-between);

      width: 100%;
    }

    .expandIcon {
      width: 0.75em;
      filter: invert(1);
      transition: transform 0.2s ease-in-out;
    }

    .closeIcon {
      transform: rotateZ(180deg);
    }

    .subItemDropdown {
      @include display-flex(column, flex-start, flex-start);

      height: 0;
      text-align: left;
      border-top: 0 solid transparent;
      transition: all 0.2s ease-in-out;
      overflow: hidden;

      li {
        @include display-flex(column, center);

        width: 100%;
        height: 10em;
      }
    }

    .subItemLink {
      display: inline-block;
      width: 100%;
      color: rgba(255, 255, 255, 65%);
      text-decoration: none;
      padding: 0.5em 10vw;

      &:hover {
        color: var(--accent-01);
        text-decoration: underline;
      }
    }

    .activeSubItem {
      color: var(--accent-01);
      font-weight: 700;
    }
  }
}

.showMobileContainer {
  transform: translateX(0);
}

@media (max-width: 900px) {
  body {
    overflow-x: scroll;
  }

  .desktopNavContainer {
    justify-content: space-between;
    padding: 0 2vw 0 3vw;

    .logoContainer {
      margin: 0;
    }

    .desktopNav {
      display: none;
    }

    .mobileNavButtonContainer {
      display: inherit;
    }
  }
}
