@use '../../variables' as *;
@use 'sass:color';

.guestbookListContainer {
  @include display-flex(column, flex-start, flex-start);

  font-size: 1.1rem;
  width: 100%;

  .entryListContainer {
    @include display-flex(column, center, flex-start);

    width: 100%;
  }

  .entryContainer {
    width: 100%;
    margin-bottom: 1.75rem;
    padding: 0.25rem 0.25rem 2rem;
    border-bottom: var(--content-border);

    &:last-of-type {
      border-bottom: 0;
    }

    p {
      margin: 0;
    }

    .label {
      font-size: 0.8em;
      color: var(--accent-01);
    }

    .nameContainer {
      @include display-flex(row);

      width: 100%;
      margin-bottom: 1rem;

      .name {
        color: var(--text-01);
        width: fit-content;
        max-width: 76%;
        font-weight: 600;
        font-size: 1.25em;
        word-wrap: break-word;
      }

      .date {
        margin-top: 0.05rem;
        margin-left: 1.5rem;
        text-align: end;
        color: var(--gray-40);
        font-size: 0.9em;
        font-weight: 600;
      }
    }

    .infoWrapper {
      @include display-flex(row, flex-start, flex-start);

      flex-wrap: wrap;
      gap: 1.25rem 3rem;
    }

    .infoContainer {
      @include content-spacing;

      width: max-content;
      max-width: 100%;
      word-wrap: break-word;
      line-height: 1.6em;
      color: var(--text-01);
    }
  }
}

@media (max-width: 900px) {
  .guestbookListContainer {
    font-size: 1.2rem;
  }
}
