@use '../../variables' as *;
@use 'sass:color';

$a4-ratio: 1.294;

[data-theme='dark'] :global(.react-pdf__Page__svg) > svg {
  filter: invert(100%);
}

.resumeContainer {
  @include display-flex(column, flex-start, flex-end);

  width: 100%;
  padding: 2em 0 1em;

  :global(.react-pdf__Document) {
    width: fit-content;
    margin: 0 auto;
  }

  $resume-width: 55rem;

  :global(.react-pdf__Page) {
    width: $resume-width;
    height: calc(($resume-width) * $a4-ratio);
  }

  :global(.react-pdf__Page__svg) {
    width: 100% !important;
    height: 100% !important;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  :global(.react-pdf__Page__textContent),
  :global(.react-pdf__Page__annotations) {
    display: none;
  }

  hr {
    height: 1px;
    background-color: var(--content-border-color);
    border: 0;
  }

  .resumeLink {
    margin: 2rem auto 1em;
  }
}

@media (max-width: 900px) {
  .resumeContainer {
    padding-top: 1em;

    $resume-width: 100vw;

    :global(.react-pdf__Page) {
      width: $resume-width;
      height: calc(($resume-width) * $a4-ratio);
    }

    .resumeLink {
      width: 75%;
    }
  }
}
