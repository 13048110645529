@use '../../variables' as *;
@use 'sass:color';

.homePageContainer {
  @include display-flex(row, center);

  width: 100%;
  height: calc(100vh - $nav-height);
  padding: 2rem 5vw;
  color: var(--text-01);
  gap: 5vw;

  .messageContainer {
    @include display-flex(column, flex-start, flex-start);

    width: min-content;
    margin-right: 5vw;
    overflow: visible;
    z-index: 2;

    .title {
      width: max-content;
      font-size: 6.5em;
      font-weight: 700;
      line-height: 0.95em;
      margin-bottom: 2vh;
    }

    .welcome {
      width: fit-content;
      font-size: 3em;
      font-weight: 300;
    }

    .startLinksContainer {
      @include display-flex(row, center);

      max-width: 100%;
      margin-top: 1.5rem;
      gap: 2em;
      flex-wrap: wrap;
    }

    .startLink {
      font-size: 1.75rem;
      font-weight: 600;
      border-bottom: 2px solid var(--accent-01);
      text-decoration: none;
      color: var(--accent-01);
      transform: scale(1);
      transition: transform 0.1s linear;
      padding: 0 0.1em;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .profileImg img {
    width: 100%;
    min-width: 0;
    max-width: 36rem;
    height: 100%;
    min-height: 0;
    border-radius: 0.75rem;
  }
}

@media (max-width: 900px) {
  .homePageContainer {
    height: fit-content;
    justify-content: flex-start;
    flex-direction: column;
    padding: 5vh 5vw 6vh;
    row-gap: 8vh;

    .messageContainer {
      width: 100%;
      max-width: inherit;
      align-items: center;
      margin: 0;

      .title {
        width: fit-content;
        font-size: max(4rem, calc(10vw));
        text-align: center;
      }

      .welcome,
      .getStarted {
        text-align: center;
      }
    }

    .profileImg img {
      width: 30rem;
      max-width: 80vw;
    }
  }
}
