@use 'sass:color';

:root {
  // colors
  --gray-05: #fafafa;
  --gray-07: #f6f6f6;
  --gray-10: #ececec;
  --gray-20: #ccc;
  --gray-30: #a0a0a0;
  --gray-40: #8f8f8f;
  --gray-50: #6c6c6c;
  --gray-60: #525252;
  --gray-70: #303030;
  --gray-75: #202020;
  --gray-80: #1e1e1e;
  --gray-90: #121212;
  //
  --blue-gray-05: #f7f7f9;
  --blue-gray-10: #e7e7ea;
  --blue-gray-30: #99b0c7;
  --blue-gray-35: #818e9c;
  --blue-gray-50: #434b53;
  --blue-gray-60: #383f45;
  --blue-gray-65: #2f343a;
  --blue-gray-70: #2b2b2f;
  --blue-gray-75: #212529;
  --blue-gray-80: #161b1f;
  //
  --blue-10: #6eb0ed66;
  --blue-40-hs: 209, 78%;
  --blue-50: #569cd6;
  --blue-60: #326fb4;
  //
  --purple-40: #be68b7;
  --purple-50: #9a4bad;
  //
  --red-30: #f35757;
  --red-40: #e44343;
  --red-50: #e42e2ecc;

  // light theme
  --accent-01: hsl(var(--blue-40-hs), 58%);
  --accent-01-85: hsl(var(--blue-40-hs), 58%, 85%);
  --accent-02: hsl(var(--blue-40-hs), 50%);
  --accent-03: hsl(var(--blue-40-hs), 42%);
  //
  --layer-01: white;
  --layer-02: var(--gray-05);
  --layer-025: var(--gray-07);
  --layer-03: var(--gray-10);
  //
  --background-01: var(--layer-01);
  --background-02: var(--layer-02);
  --background-03: var(--layer-03);
  //
  --text-01: black;
  --text-02: var(--gray-60);
  --text-03: var(--gray-50);
  --text-error: var(--red-50);
  --text-link: var(--blue-60);
  --text-link-visited: var(--purple-50);
  --text-link-active: var(--red-40);
  //
  --button-primary-01: var(--accent-01);
  --button-primary-02: var(--accent-02);
  --button-primary-03: var(--accent-03);
  //
  --input-01: var(--layer-01);
  --nav-background-color: var(--blue-gray-75);
  --nav-link-color: var(--gray-10);
  --content-border-color: var(--gray-20);
  --content-border: 1px solid var(--content-border-color);
  --logo-base-color: #343e4d;
}

// dark theme
[data-theme='dark'] {
  //
  --layer-01: black;
  --layer-02: var(--gray-90);
  --layer-025: var(--gray-80);
  --layer-03: var(--gray-80);
  //
  --background-01: var(--layer-01);
  --background-02: var(--layer-01);
  --background-03: var(--layer-03);
  --nav-background-color: var(--blue-gray-80);
  //
  --text-01: var(--gray-10);
  --text-02: var(--gray-20);
  --text-03: var(--gray-30);
  --text-error: var(--red-50);
  --text-link: var(--blue-50);
  --text-link-visited: var(--purple-40);
  --text-link-active: var(--red-30);
  //
  --button-primary-01: var(--accent-02);
  --button-primary-02: var(--accent-03);
  --button-primary-03: hsl(var(--blue-40-hs), 35%);
  //
  --input-01: var(--layer-02);
  --nav-link-color: var(--gray-20);
  --content-border-color: var(--gray-60);
  --content-border: 1px solid var(--gray-70);
}

// vars
$nav-height: 4rem;

// mixins
@mixin display-flex(
  $direction,
  $justification: flex-start,
  $alignment: center
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
}

@mixin content-spacing {
  line-height: 1.7em;
}
