@use '../../variables' as *;
@use 'sass:color';
@import 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap';

@mixin name {
  padding: 0.1rem 0.5rem 0.2rem;
  border-radius: 10rem;
  font-weight: 300;
  font-family: Ubuntu, sans-serif;
}

.logoContainer {
  font-size: 1.5rem;

  a {
    color: white;
    text-decoration: none;
  }

  .firstName {
    @include name;

    background-color: var(--logo-base-color);
    padding-right: 0;
    word-spacing: 0;
    border: 0.25em solid var(--logo-base-color);
  }

  .lastName {
    @include name;

    background-color: var(--accent-02);
    margin-left: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.6rem;
  }
}
