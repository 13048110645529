@use '../../variables' as *;
@use 'sass:color';

.contentContainer {
  width: 55vw;
  max-width: 58rem;
  font-size: 1.1rem;
  margin: 0 auto;
  color: var(--text-01);

  h1 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 3.75rem;
    word-wrap: break-word;
  }

  h2 {
    margin-top: 4rem;
    scroll-margin-top: 5rem;
    font-size: 2rem;

    &:first-of-type {
      margin-top: 2rem;
    }
  }

  h3 {
    font-weight: 600;
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    @include content-spacing;

    text-align: justify;
  }

  a {
    display: block;
    width: fit-content;
    font-weight: 700;
    margin: 2rem auto 2.5rem;
    text-decoration: none;
    color: var(--text-link);

    &:visited {
      color: var(--text-link-visited);
    }

    &:active {
      color: var(--text-link-active);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 900px) {
  .contentContainer {
    width: 100%;
    max-width: inherit;
    font-size: 1.2em;

    h1 {
      font-size: 3rem;
    }

    p {
      letter-spacing: 0.075em;
      line-height: 1.6em;
      text-align: left;
    }

    a {
      font-size: 1.3rem;
    }
  }
}
