@use '../../variables' as *;
@use 'sass:color';

.formContainer {
  @include display-flex(column, flex-start, flex-start);

  .formError {
    box-shadow: 0 0 0 0.1em var(--text-error);
    border-bottom-color: var(--text-error);
  }

  .formLabel {
    margin-bottom: 0.5rem;
    font-size: 0.9em;
    color: var(--text-03);
    word-spacing: 0;

    span {
      color: var(--text-error);
    }
  }

  .formInput {
    font-family: inherit;
    margin-bottom: 1.5rem;
    background-color: var(--input-01);
  }

  .captchaContainer {
    width: 304px;
    height: 78px;
    margin-bottom: 1.5rem;
  }

  .formSubmit {
    justify-self: flex-end;
  }
}

@media (max-width: 900px) {
  .formContainer {
    font-size: 1.2rem;

    .captchaContainer {
      margin-bottom: 2rem;
    }
  }
}
