@use '../../variables' as *;
@use 'sass:color';

$nav-background-accent: var(--background-03);

.sideNavContainer {
  height: 100%;
  width: 100%;
  min-width: 11.5rem;
  padding: 0.75rem;
  box-sizing: border-box;
  background-color: var(--background-02);
  z-index: 2;
  font-size: 1.1rem;
  border-right: var(--content-border);
  overflow-y: auto;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .sideNavItem {
      margin: 0.5rem 0;
      font-weight: 600;
      word-wrap: break-word;
    }

    .sideNavLink {
      display: block;
      width: 100%;
      color: var(--text-02);
      text-decoration: none;
      padding: 0.5rem 1rem;
      box-sizing: border-box;
      border-radius: 0.4rem;

      &:hover {
        background-color: $nav-background-accent;
      }
    }

    .activeSideNavLink {
      color: var(--accent-01);
      background-color: var(--background-03);
    }
  }
}

.hideSideNav {
  visibility: hidden;
  min-width: inherit;
}
